import React, { useEffect, useState } from "react";
import { Document, Page } from "react-pdf";
import { pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

//pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//  'pdfjs-dist/build/pdf.worker.min.mjs',
//  import.meta.url,
//).toString();
pdfjs.GlobalWorkerOptions.workerSrc = '//unpkg.com/pdfjs-dist@4.8.69/build/pdf.worker.mjs'

export default function ReportPDF({ documentPath, setDocumentPath }) {

  const [numPages, setNumPages] = useState("");
  const [pageNumber, setPageNumber] = useState("");



  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const closeModal = () => {
    setDocumentPath("")
  }


  return (
   
    <div className="modal-report" >
      <div className="all-page-container">
        <div className="download-pdf-button">
        <a href={documentPath} download>
          <button><svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15 20L8.75 13.75L10.5 11.9375L13.75 15.1875V5H16.25V15.1875L19.5 11.9375L21.25 13.75L15 20ZM7.5 25C6.8125 25 6.22396 24.7552 5.73438 24.2656C5.24479 23.776 5 23.1875 5 22.5V18.75H7.5V22.5H22.5V18.75H25V22.5C25 23.1875 24.7552 23.776 24.2656 24.2656C23.776 24.7552 23.1875 25 22.5 25H7.5Z" fill="#A7A7A7"/>
</svg></button>
        </a>
        </div>
        <div className="close-pdf-button" onClick={closeModal}>
        <a >
          <button><svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8 23.75L6.25 22L13.25 15L6.25 8L8 6.25L15 13.25L22 6.25L23.75 8L16.75 15L23.75 22L22 23.75L15 16.75L8 23.75Z" fill="#A7A7A7"/>
</svg></button>
        </a>
        </div>
        <Document file={documentPath} onLoadSuccess={onDocumentLoadSuccess} onLoadError={(error) => alert('Error while loading document! ' + error.message)}>
          {Array.from(new Array(numPages), (el, index) => (
            <Page key={`page_${index + 1}`} pageNumber={index + 1} renderAnnotationLayer={false} renderTextLayer={false} />
          ))}
        </Document>

      </div>

    </div>
  );
}
